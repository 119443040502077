<template>
  <ion-page>
    <ion-content :fullscreen="true">
        <div class="ios hydrated container-page-header">
            <div class="page-title">
                <div class="orange-circle" @click="backPage">
                    <uil-angle-left size="25px" />
                </div>
                <h1>Sections</h1>
            </div>

            <div class="sections-container" v-if="!loading">
                <div v-for="section in sections" :key="section.id" class="section-container">
                    <div class="section-circle" @click="openJob(job_id, section)">
                        <svg
                            class="progress-ring"
                            width="90"
                            height="90">
                            <circle
                                class="progress-ring__circle"
                                :style="circleStyle(section.id)"
                                stroke="red"
                                stroke-width="3"
                                fill="transparent"
                                r="40"
                                cx="45"
                                cy="45">
                            </circle>
                        </svg>
                        <!-- <div class="section-name">{{ section.name }}</div> -->
                        <div class="section-name">
                            <!-- <component size="25px" :is="sectionIcon(section.id)"></component> -->
                            <component class="orange-icon" size="50px" :is="getSectionComponent(section)"></component>
                        </div>
                        <!-- <div class="section-name">
                           <component size="25px" :is="section.loadedIcon"></component>
                        </div> -->
                        <template v-if="section.tick == true">
                            <uis-check-circle size="20px" class="section-tick" />
                        </template>
                    </div>
                    <div class="section-title">
                        {{ section.name }}
                    </div>
                </div>

            </div>

            <div class="page-title extra-margin">
                <h2>Add Section</h2>
            </div>

            <div class="sections-container">
                <template v-if="numberSectionsCanAdd > 0"> 
                    <div v-for="section in sections" :key="section.id" class="section-container add-section">
                        <template v-if="section.repeatable == 1"> 
                            <div class="section-circle">
                                <div class="section-name">
                                    <component size="60px" :is="section.component"></component>
                                </div>
                                <div class="add-section-btn" @click="addSection(section)">
                                    <div class="orange-circle">
                                        <uil-plus size="20px" />
                                    </div>
                                    <div class="add-text">Add</div>
                                </div>
                            </div>
                            <div class="section-title">
                                {{ section.name }}
                            </div>
                         </template>
                    </div>
                </template>
                <template v-else>
                    <div class="no-message">There are no repeatable sections that can be added</div>
                </template> 
            </div>
        </div>


    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, modalController } from '@ionic/vue';
import { mapGetters } from 'vuex';
import { UilPlus, UilQuestion, UilAngleLeft } from '@iconscout/vue-unicons'
import {defineAsyncComponent} from "vue";
import SectionName from '../modals/SectionName.vue';
import { UisCheckCircle } from '@iconscout/vue-unicons-solid'

export default {
    name: 'Sections',
    components: {
        IonContent,
        IonPage,
        UilPlus, UilQuestion, UilAngleLeft, UisCheckCircle
    },
    data () {
        return {
            job_id: this.$route.params.id,
            sections : {},
            numberSectionsCanAdd : 0,
            modal : null,
            job : null,
            loading : true
        }
    },
    methods: {
        // getSection(id) {
        //     for(let i = 0; i < this.sections.length; i++){
        //         let current = this.sections[i];
        //         if(current.id == id){
        //             return current
        //         }
        //     }
        //     return false;
        // },
        circleStyle() {
            let section = this.getSection;
            let circumference = 40 * 2 * Math.PI;

            let color = "#B41F1F";

            if(section.progress < 75){
                color = "#F7B900";
            } else if(section.progress < 25){
                color = "#B41F1F";
            } else {
                color = "#1D9F67";
            }

            return {
                "stroke-dasharray": `${circumference}, ${circumference}`,
                "stroke-dashoffset": section.progress_calculated,
                "stroke" : color
            }
        }  ,
        openJob(job_id, section){
            this.$store.commit("brightchecker/SET_CURRENT_SECTION", section);
            this.$router.push({ path: '/job/'+job_id+'/section/' + section.id })
        },
        getNextNegativeId(data){              
            let low_id = -1;
            let array = Object.values(data)

            let lowest = array.reduce(function(prev, curr) {
                return prev.id < curr.id ? prev : curr;
            });
            if(lowest.id > 0){
                low_id = -1;
            } else {
                low_id = lowest.id - 1;
            }

            if(low_id < 0){
                low_id = low_id.toString();
            }

            return low_id;
        },
        async addSection(section) {
            let newSection_str = JSON.stringify(section);
            let newSection = JSON.parse(newSection_str);

            newSection.id = this.getNextNegativeId(this.sections);

            const modal = await modalController
                .create({
                    component: SectionName,
                    cssClass: 'section-modal',
                    componentProps: {
                        title: 'Section Name',
                        section_name : section.name
                    },
                })

            modal.onDidDismiss().then((data) => {

                if(data.data != "" && data.data != undefined && data.data != false && data.data != section.name && typeof data.data != 'undefined'){
                    newSection.name = data.data;

                    newSection.repeatable = 0;
                    newSection.tick = false;
                    let icon = newSection.icon_import;
                    newSection.component = defineAsyncComponent(() => import(`@iconscout/vue-unicons/icons/${icon}.vue`))

                    let newItems = newSection.items;
                    for(let item_prop in newItems){
                        let current_item = newItems[item_prop];
                        current_item.id = this.getNextNegativeId(newItems);
                        let questions = current_item.questions;

                        let question_id_map = {};
                        let question_copies = {};

                        for(let question_prop in questions){
                            let current_question = questions[question_prop];
                            let original_id = current_question.id;

                            current_question.id = this.getNextNegativeId(questions);
                            current_question.old_id = original_id;

                            question_id_map[original_id] = current_question.id;
                            question_copies[original_id] = current_question;

                            current_question.answer.answer = null;
                            current_question.answer.date_answered = null;
                            current_question.answer.id = 0;
                            current_question.answer.answered_by = null;

                            current_question.answer_note.type = null;
                            current_question.answer_note.text = null;
                            current_question.answer_note.video = null;
                            current_question.answer_note.video = [];
                        }

                            for(let question_prop in questions){
                                let current_question = questions[question_prop];
                                if(current_question['conditional']){ 
                                    // get copied question
                                    let q = question_copies[current_question.old_id];
                                    let new_conditional = [];
                                    new_conditional[0] = question_id_map[q.conditional[0]];
                                    new_conditional[1] = q.conditional[1];
                                    new_conditional[2] = q.conditional[2];
                                    q.conditional = new_conditional;
                                }
                            }
                    }
                    // newItems.forEach((current_item) => {
                    //     current_item.id = this.getNextNegativeId(newItems);
                    //     let questions = current_item.questions;

                    //     let question_id_map = {};
                    //     let question_copies = {};

                    //     questions.forEach((current_question) => {
                    //         let original_id = current_question.id;

                    //         current_question.id = this.getNextNegativeId(questions);
                    //         current_question.old_id = original_id;

                    //         question_id_map[original_id] = current_question.id;
                    //         question_copies[original_id] = current_question;

                    //         current_question.answer.answer = null;
                    //         current_question.answer.date_answered = null;
                    //         current_question.answer.id = 0;
                    //         current_question.answer.answered_by = null;

                    //         current_question.answer_note.type = null;
                    //         current_question.answer_note.text = null;
                    //         current_question.answer_note.video = null;
                    //         current_question.answer_note.video = [];
                    //     })

                    //     questions.forEach((current_question) => {
                    //         if(current_question['conditional']){ 
                    //             // get copied question
                    //             let q = question_copies[current_question.old_id];
                    //             let new_conditional = [];
                    //             new_conditional[0] = question_id_map[q.conditional[0]];
                    //             new_conditional[1] = q.conditional[1];
                    //             new_conditional[2] = q.conditional[2];
                    //             q.conditional = new_conditional;
                    //         }
                    //     })
                    // })

                    this.sections[newSection.id] = newSection;
                    this.$store.dispatch("brightchecker/saveJob", this.job);

                    this.calculateProgress();
                }
            });

            return modal.present();

            // this.sections.push(newSection)

        },
        calculateProgress(){
            let full_progress = 0;
            for(let section_prop in this.sections){
                let current_section = this.sections[section_prop];
                let total_completed = 0;
                let total_not_complete = 0;

                for(let item_prop in current_section.items){
                    let completed = 0;
                    let not_required = 0;
                    let not_complete = 0;

                    let current_item = current_section.items[item_prop];

                    for(let question_prop in current_item.questions){
                        let current_question = current_item.questions[question_prop];

                        if(current_question.answer.answer != null){
                            completed++;
                            total_completed++;
                        }
                        // correct
                        if(current_question.answer.answer == null && current_question.required == 2){
                            not_required++;
                        }
                        
                        if(current_question.answer.answer == null && current_question.required == 1){
                            not_complete++;
                            total_not_complete++;
                        }

                        if(current_question.answer.answer == null && current_question.required == 0){
                            // not_complete++;
                            // total_not_complete++;

                            not_required++;
                        }
                    }
                    current_item.completed = completed;
                    current_item.not_required = not_required;
                    current_item.not_complete = not_complete;

                    if(current_item.not_complete == 0 && current_item.not_required == 0){
                        current_section.tick = true;
                    }
                }

                let progress = (total_completed / (total_not_complete + total_completed)) * 100;
                current_section.progress = progress;
                let circumference = 40 * 2 * Math.PI;
                let offset = circumference - (progress / 100 * circumference);
                current_section.progress_calculated = offset;

                full_progress = full_progress + progress;
            }
            let expected_total = 100 * Object.keys(this.sections).length;

            this.job.progress = (full_progress / expected_total)
            this.job.progress = (full_progress / expected_total)
            // this.$store.dispatch("auth/saveJob", this.job);

            if(expected_total == full_progress){
                return true;
            } else {
                return false;
            }
        },
        getSectionComponent(section){
            let icon = section.icon_import;
            icon = icon.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, "");
            return defineAsyncComponent(() => import(`@iconscout/vue-unicons/icons/${icon}.vue`))
        },
        backPage(){
            this.$router.back()
        },
    },
    computed: {
        ...mapGetters({
            getJob: 'brightchecker/current_job',
            getSection: 'brightchecker/current_section',
        }),
    },
    beforeUpdate() {
        this.loading = true;
    },
    updated() {
        this.job = this.getJob;
        if(Object.keys(this.job).length === 0){
            this.job = this.getJobs[this.job_id];
            this.$store.commit("brightchecker/SET_CURRENT_JOB", this.job);
        }
        this.sections = this.job.survey.sections;

        for(let section_prop in this.sections){
            if(this.sections[section_prop].repeatable == 1 || this.sections[section_prop].repeatable == "1"){
                this.numberSectionsCanAdd++;
            }
        }
        this.calculateProgress();
        this.loading = false;
    }
}

</script>

<style scoped lang="scss">
    h1, h2 {
        text-align: center;
        font-size: 20px;
        font-weight: 800;
    }

    .sections-container {
        transition: all 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 40px;
        .section-container {
            margin-bottom: 10px;
            &.add-section {
                .section-circle {
                    height: 90px;
                    width: 90px;
                    .section-name {
                        color: #CBCACA;
                    }

                    .add-section-btn {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-weight: 800;
                        font-family: 'Raleway', sans-serif;
                        z-index: 10;
                        .orange-circle {
                            height: 20px;
                            width: 20px;
                            padding: 0px;
                        }
                        .add-text {
                            margin-top: 5px;
                            font-size: 14px;
                            font-weight: 800;
                        }
                    }
                }
                
                .section-title {
                    color: #373636;
                }
            }
            .section-circle {
                background-color: #ffffff;
                border-radius: 50%;
                height: 90px;
                width: 90px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px;
                text-align: center;
                font-size: 12px;
                box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
                position: relative;
                .section-name {
                    position: absolute;
                    padding: 0px 8px;
                }
                svg.orange-icon {
                    color: #DB9123;
                }
            }

            .orange-circle {
                height: 45px;
                width: 45px;
            }
            .section-title {
                text-align: center;
                font-size: 14px;
                font-weight: 800;
                padding: 0px 5px;
            }
        }
    }

    .extra-margin {
        margin-top: 40px;
    }

    .progress-ring {
        position: absolute;
    }

    .progress-ring__circle {
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }

    .no-message {
        text-align: center;
        width: 60%;
    }

    .page-title {
        position: relative;
        .orange-circle {
            justify-content: center;
            align-items: center;
            padding: 0px;
            position: absolute;
            left: 20px;
            color: black;
        }
    }

    .section-tick {
        position: absolute;
        right: 0;
        bottom: 0;
        fill: #1D9F67;
        width: 30px;
        height: 30px;
        background-color: white;
        border-radius: 50%;
    }
</style>